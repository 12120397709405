
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	data() {
		return {
			stateHeightMakes: false,
		}
	},
	props: {
		forChoseModal: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			allMarks: 'marks/marks/allMarks',
			currentMark: 'modal/modal-choose/currentMark',
			popularMarks: 'marks/marks/popularMarks',
			modalMarks: 'modal/modal-marks/modalMarks',

			folders: "folders/folders/folders",
			generations: "generations/generations/generations"

		}),
		showAllButton() {
			if (this.$device.isMobile && this.marksArray) {
				return this.marksArray.length > 10
			} else if (this.marksArray) {
				return this.marksArray.length > 21
			}
		},
		makesVisibleSwitcher() {
			if (!this.$route.params.mark && this.marksList2.length) return true
			if (this.$route.params.mark && !this.$route.params.model && this.folders.length) return true
			if (this.$route.params.mark && this.$route.params.model && !this.$route.params.car && this.generations.length) return true
			return false
		},
		// sortedMarks(){
		// 	return this.$_.sortBy(this.allMarks, [function (mark) {
		// 		return mark.title;
		// 	}])
		// },
		popular() {
			return this.popularMarks.slice(0, 7)
		},
		computedStateHeightMakes() {
			if (this.stateHeightMakes == true) {
				return true;
			} else {
				return false;
			}
		},
		marksLength() {
			return this.allMarks ? this.allMarks.reduce((prev, next) => prev += next.offers_count, 0) : ''
		},
		marksList2() {
			let marks = [...this.allMarks];
			let count = 8;

			if (this.$route.name == 'Credit' || this.$route.name == 'Exchange') {
				count = 16;
			}

			if (this.$device.isMobile) {
				count = 8;
			} else {
				count = 18;
			}
			marks = marks.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
			if (!this.stateHeightMakes) {
				return marks.slice(0, count).sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()));
			}
			else {
				marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
				return marks;
			}

			// let marks = [...this.allMarks].sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count)).sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
			// return !this.stateHeightMakes ? marks.slice(0, 18) : marks
		},
		marksList() {
			let marks = [...this.allMarks]
			let count = 12;

			if (this.modalMarks) {
				count = 16;
			}
			if (this.$route.name == 'Credit' || this.$route.name == 'Exchange') {
				count = 16;
			}

			//если открыта модалка с хедера
			if (this.computedStateHeightMakes && this.$device.isMobile) {
				// marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
				// 	return marks;
				count = 16;
			}
			//если открыта модалка с хедера

			//поведение обычных марок
			if (this.$device.isMobile) {
				marks = marks.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
				if (!this.stateHeightMakes) {
					return marks.slice(0, count);
				}
				else {
					marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
					return marks;
				}
			}
			else {
				marks = marks.sort((a, b) => a.slug.toLowerCase().localeCompare(b.slug.toLowerCase()))
				return marks;
			}
			//поведение марок
		}
	},
	methods: {
		...mapActions({
			chooseMark: 'modal/modal-choose/chooseMark',
			chooseModel: 'modal/modal-choose/chooseModel',
			chooseGeneration: 'modal/modal-choose/chooseGeneration',
		}),
		...mapMutations({
			setModalSearch: 'modal/modal-search/setModalSearch',
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks'
		}),
		clickMarks() {
			this.setModalMarks(false)
			this.setModalSearch(false)
		},
		markClick(item) {
			this.setModalMarks(false)
			this.$router.push('/cars/' + item.slug)
		},
		setStateHeightMakes() {
			this.stateHeightMakes = !this.stateHeightMakes;
			if (this.stateHeightMakes == false) {
				// this.$refs.makes_list.scrollIntoView();
				// console.log('setStateHeightMakes')
				// window.scrollBy(0, -150);
			}
		}
	},
	mounted() {
		if (this.$route.name == 'Credit' || this.$route.name == 'Trade-in' || this.$route.name == 'Buyout') {
			this.stateHeightMakes = false;
		}

		setTimeout(() => {
			if (this.$route.params.mark && !this.$route.params.model) {
				// this.$refs.modelScroll.scrollIntoView();
				// window.scrollBy(0, -76)
			}
			if (this.$route.params.model) {
				// this.$refs.generationScroll.scrollIntoView();
				// window.scrollBy(0, -76)
			}

		}, 600)


		// console.log(this.allMarks)

	},
	
}
