

export default {
	props: {
		banks: {
			type: Array,
			default: []
		},
	},
	data() {
		return {
			sw: null
		}
	},
	computed: {
		mainBanks() {
			return this.banks.filter(x => x.name === "Сбербанк" | x.name === "Альфа-Банк" | x.name === "Тинькофф Банк")
		},
		otherBanks() {
			return this.banks.filter(x => x.name !== "Сбербанк" && x.name !== "Альфа-Банк" && x.name !== "Тинькофф Банк")
		}
	},
	mounted() {
		// setTimeout(() => {
		// let leftPadding = this.$refs.asdd.getBoundingClientRect().left
		// let width = this.$refs.asdd.getBoundingClientRect().width
		// let aaa = leftPadding * 2 / (width / 2) + 2
		// let aaa1 = leftPadding * 2 / (width / 1) + 1.5
		if (this.$route.path !== '/banks')
			this.sw = new swiper.default('.swiper.swiper--banks', {
				modules: [swiper.Navigation, swiper.Autoplay, swiper.Pagination, swiper.Grid],
				loop: false,
				// autoplayDisableOnInteraction: true,
				autoplay: false,
				// loop:true,
				initialSlide: 0,
				// centeredSlides: true,
				watchSlidesProgress: true,
				// centeredSlidesBounds:true,
				// roundLengths: true, 
				pagination: {
					clickable: true,
					el: ".swiper-pagination.swiper-pagination--banks",
					type: "bullets",
				},

				// centerInsufficientSlides:true,
				// slidesOffsetAfter: this.$refs.asdd.getBoundingClientRect().left,
				// cssMode:true,
				// slidesOffsetBefore: leftPadding,
				// slidesOffsetAfter: leftPadding,
				slidesPerView: 2,
				spaceBetween: 10,
				grid: {
					fill: 'rows',
					rows: 3,

				},
				breakpoints: {
					// when window width is >= 320px
					// 220: {
					// 	slidesPerView: 1.25,
					// 	spaceBetween: 20
					// },
					600: {
						slidesPerView: 6,
						spaceBetween: 24,
						grid: {
							fill: 'rows',
							rows: 1,

						},
					}

				},

				navigation: {
					nextEl: '.swiper-button-next--banks',
					prevEl: '.swiper-button-prev--banks',
				},
			})


		// sw.on('slideChange', (swiper) => this.activeSlide = swiper.activeIndex)
		// })
	}
}
