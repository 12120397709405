
import { mapGetters, mapActions } from 'vuex';
import callback from "@/mixins/callback";

export default {
    mixins: [callback],
    props: {
        isContactPage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            ready: false,
            markerFill: {
                enabled: true,
                color: 'black',
                opacity: 1,
                // imageHref
            },
            markerIcon: {
                layout: 'default#image', // 'default#imageWithContent' для использования с контентом
                imageHref: require('~/assets/icons/mapMarker2.svg'), // адрес изображения или data:image/svg+xml;base64
                imageSize: [43, 55], // размер иконки в px
                imageOffset: [-22, -55], // смещение иконки в px,
                /* Следующие поля актуальны для layout: 'default#imageWithContent' */
                content: 'some content here', // содержимое контента
                contentOffset: [-22, -55], // смещение контента в px,
                contentLayout: '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>' // строковый HTML шаблон для контента
            }
        }
    },
    computed: {
        ...mapGetters({
            settings: 'settings/settings/settings',
        }),
        mapstyle() {
            return !this.$device.isMobile ? { width: '100vw', height: '320px' } : { width: '100vw', height: '90vw' }
        },
        coordinates() {
            return this.$device.isMobile ? this.settings.coordinates.split(',') : this.settings.coordinates.split(',')
        },
    },
    methods: {
        ...mapActions({
            openModal: 'modal/modal-main/openModal',

        }),
        // async callback() {
		// 	let payload = {
		// 		modal_data: { title: 'Обратная связь', sub_title: 'Мы работаем с 9:00 до 21:00.', text: 'Оставьте заявку, и мы свяжемся с Вами в рабочее время.' },
		// 		modal_component: 'modal-timer',
		// 		modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
		// 		modal_sub_title: {}
		// 	}
		// 	this.openModal(payload)
		// },
        mapButton__click() {
            let payload = {
                modal_data: {},
                // modal_component: 'modal-credit',
                modal_component: 'modal-timer',
                modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
                modal_sub_title: {}
            }
            this.openModal(payload)
        }
    },
    mounted() {
        if (!this.isContactPage) {
            if (!this.$device.isMobile) {
                const leftpadding = document.getElementsByClassName('head1 blue--text title')[0].getBoundingClientRect().left
                this.$refs.map__leftSide.animate({
                    right: `${leftpadding}px`,
                    opacity: 1
                }, {
                    duration: 1000, fill: 'forwards', easing: 'ease-in-out'
                })
            } else {
                this.$refs.map__leftSide.animate({
                    opacity: 1
                }, {
                    duration: 1000, fill: 'forwards', easing: 'ease-in-out'
                })
            }
        }



        setTimeout(() => {
            this.ready = true
        }, 500)
    },

}
