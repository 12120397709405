
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			activeSlide: 0
		}
	},

	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		service_component() {
			return this.$device.isMobile ? 'services-item-small-desktop' : 'services-item-big-desktop'
		},
		services() {
			return [
				{
					title: 'КРЕДИТ',
					tag: 'credit',
					content: [
						// `Автокредитование на выгодных условиях:`,
						'первый взнос от 0 ₽',
						`скидка ${this.settings.sale_credit}`,
						'досрочное погашение без штрафов и комиссий',
						// '- сумма до 5 000 000 р',
						// '- срок до 7 лет'
					],
					image: {
						x1: require('~/assets/img/services/services2@x1.webp'),
						x2: require('~/assets/img/services/services2@x2.webp')
					},
					buttonFunc: () => this.$router.push(`/credit`)
				},
				{
					title: 'TRADE-IN',
					tag: 'tradein',
					content: [
						'выгода до 250 000 ₽',
						'оценим ваш автомобиль по рыночной стоимости',
						'бесплатный выезд специалиста по оценке',
					],
					image: {
						x1: require('~/assets/img/services/services1@x1.webp'),
						x2: require('~/assets/img/services/services1@x2.webp')
					},
					buttonFunc: () => this.$router.push(`/exchange`)

				},
				{
					title: 'ВЫКУП',
					tag: 'buyout',
					content: [
						'оценка вашего авто по рыночной стоимости',
						'выпата день в день',
						'бесплатный выезд специалиста по оценке',
					],
					image: {
						x1: require('~/assets/img/services/services3@x1.webp'),
						x2: require('~/assets/img/services/services3@x2.webp')
					},
					buttonFunc: () => this.$router.push(`/buyout`)

				},
				{
					title: 'РАССРОЧКА',
					tag: 'installment',
					content: [
						'покупка автомобиля без переплат - 0%',
						'высокий процент одобрения',
					],
					image: {
						x1: require('~/assets/img/services/services4@x1.webp'),
						x2: require('~/assets/img/services/services4@x2.webp')
					},
					buttonFunc: () => this.$router.push(`/installments`)

				}
			]
		},
		activeSlideTitle() {
			return this.services[this.activeSlide]
		}
	},
	mounted() {
		setTimeout(() => {

			const sw = new swiper.default('.swiper--catalog.swiper.swiper--services', {
				modules: [swiper.Navigation, swiper.Autoplay, swiper.Pagination],
				loop: false,
				// autoplayDisableOnInteraction: true,
				autoplay: false,
				// loop:true,
				initialSlide: 0,
				// centeredSlides: true,
				watchSlidesProgress: true,
				// centeredSlidesBounds:true,
				// roundLengths: true, 
				pagination: {
					// el: '.swiper-pagination',
					// type: 'bullets',
					el: ".swiper-pagination.swiper-pagination--services",
					type: "bullets",
				},
				// centerInsufficientSlides:true,
				// slidesOffsetAfter: this.$refs.asdd.getBoundingClientRect().left,
				// cssMode:true,
				// slidesOffsetBefore: leftPadding,
				// slidesOffsetAfter: leftPadding,
				slidesPerView: 1,
				spaceBetween: 50,
				breakpoints: {
					// when window width is >= 320px
					// 220: {
					// 	slidesPerView: 1.25,
					// 	spaceBetween: 20
					// },
					// 600: {
					// 	slidesPerView: 2,
					// 	spaceBetween: 24,

					// }

				},

				navigation: {
					nextEl: '.catalog__list .swiper-button-next--services',
					prevEl: '.catalog__list .swiper-button-prev--services',
				},
			})


			sw.on('slideChange', (swiper) => this.activeSlide = swiper.activeIndex)
		})

	},

}
