
import { mapGetters, mapActions } from "vuex";
import callback from "@/mixins/callback";

export default {
	mixins: [callback],
	props: {
		pageTitle: String,
		salonInfo: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			// new Array(15)
			contactsImages: new Array(7).fill(1).map((x, y) => require(`~/assets/img/slider-dealer/slider-dealer-${x + y}@1x.webp`))
		}
	},
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings',
			isNight: "settings/settings/isNight",
		}),
	},
	methods: {
		...mapActions({
			openModal: 'modal/modal-main/openModal',

		}),
		// async callback() {
		// 	let payload = {
		// 		modal_data: { title: 'Обратная связь', sub_title: 'Мы работаем с 9:00 до 21:00.', text: 'Оставьте заявку, и мы свяжемся с Вами в рабочее время.' },
		// 		modal_component: 'modal-timer',
		// 		modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
		// 		modal_sub_title: {}
		// 	}
		// 	this.openModal(payload)
		// },
	}

}
