
import seoTags from "@/mixins/seoTags";
// import LazyHydrate from 'vue-lazy-hydration';
export default {
  mixins: [seoTags],
  // 	components: {
  //     LazyHydrate,
  //   },
  data() {
    return {
      ready: false
    }
  },
  mounted() {
    setTimeout(function () {
      window.scrollTo(0, -100);
    }, 1);
    setTimeout(() => {
      this.ready = true
    }, 650)
  },

}
